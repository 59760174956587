<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.cjWorkOrderCode" placeholder="请输入工单号" />
            <a-input v-model:value="params.orderCode" placeholder="请输入生产订单号" />
            <a-input v-model:value="params.barcode" placeholder="请输入在制品条码" />
            <a-input v-model:value="params.productionBatchNo" placeholder="请输入生产批次号" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, watch, unref } from "vue";
import { useRouter } from "vue-router";
import Select from "@/components/Select";
export default defineComponent({
    props: ["eventLevelOptions"],
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const state = reactive({
            submitUserOptions: [],
            teamOptions: [],
            processOptions: [],
            date: [],
            params: {
                cjWorkOrderCode: undefined,
                orderCode: undefined,
                barcode: undefined,
                productionBatchNo: undefined,
            },
            isJumpWithCode: false,
        });

        const init = code => {
            if (code) {
                state.isJumpWithCode = true;
                state.params.barcode = code?.split("?")[0];
            }
            search();
        };

        onMounted(() => {
            init(router.currentRoute.value.query?.barcode);
        });

        watch(
            () => unref(router.currentRoute).query,
            newVal => {
                init(newVal?.barcode);
            }
        );

        const reset = () => {
            state.params.cjWorkOrderCode = undefined;
            state.params.orderCode = undefined;
            state.params.barcode = undefined;
            state.params.productionBatchNo = undefined;
            state.date = [];
            search();
        };

        const search = () => {
            state.params.cjWorkOrderCode = state.params.cjWorkOrderCode?.trim();
            state.params.orderCode = state.params.orderCode?.trim();
            state.params.barcode = state.params.barcode?.trim();
            state.params.productionBatchNo = state.params.productionBatchNo?.trim();
            emit("search", state.params, state.isJumpWithCode);
            state.isJumpWithCode = false;
        };

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
