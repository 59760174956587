<template>
    <a-descriptions :column="3">
        <a-descriptions-item label="在制品条码">
            {{ data?.barcode }}
        </a-descriptions-item>
        <a-descriptions-item label="工单号">
            {{ data?.cjWorkOrderCode }}
        </a-descriptions-item>
        <a-descriptions-item label="工单类型">
            {{ data?.productionLineZh }}
        </a-descriptions-item>
        <a-descriptions-item label="生产订单号">
            {{ data?.orderCode }}
        </a-descriptions-item>
        <a-descriptions-item label="项目名称">
            {{ data?.workOrder?.projectName }}
        </a-descriptions-item>
        <a-descriptions-item label="项目编码" :span="3">
            {{ data?.workOrder?.projectCode }}
        </a-descriptions-item>
        <a-descriptions-item label="产品名称">
            {{ data?.productName }}
        </a-descriptions-item>
        <a-descriptions-item label="产品编码">
            {{ data?.productCode }}
        </a-descriptions-item>
        <a-descriptions-item label="生产批次号">
            {{ data?.productionBatchNo }}
        </a-descriptions-item>
        <a-descriptions-item label="规格型号" span="3">
            {{ data?.spec }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        data: {
            default: null,
        },
    },
});
</script>
